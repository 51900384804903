<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ $t("invoiceModal.invoice") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="value === null || value === undefined" class="text-center">
            <Vue3Lottie
              :animationData="iconJSON"
              :height="160"
              :width="300"
              :key="key"
            />
            <div class="bold-24 p-5">{{ $t("invoiceModal.noInvoiceFound") }}</div>
          </div>
          <div v-else-if="file === null">
            <Vue3Lottie
              :animationData="iconJSON"
              :height="160"
              :width="300"
              :key="key"
            />
          </div>
          <div v-else>
            <div class="row">
              <iframe :src="file" width="100%" height="500px"></iframe>
            </div>
            <div class="row mt-4">
              <div class="col-3 mx-auto">
                <a
                  :href="file"
                  class="btn btn-type-4 w-97 text-center me-auto"
                  target="_blank"
                >
                  <span class="bold-14 text-gradient">{{ $t("invoiceModal.download") }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue3Lottie } from "vue3-lottie";
import loadFileJSON from "../../assets/animations/loadFile.json";
import emptyJSON from "../../assets/animations/empty.json";
import http from "../../modules/http";
const bootstrap = require("bootstrap");

export default {
  name: "InvoiceModal",
  components: { Vue3Lottie },
  props: {
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
      iconJSON:
        this.modelValue === null || this.modelValue === undefined
          ? emptyJSON
          : loadFileJSON,
      file: null,
      key: 0,
    };
  },
  watch: {
    modelValue() {
      this.file = null;
      this.value = this.modelValue;
      this.iconJSON =
        this.modelValue === null || this.modelValue === undefined
          ? emptyJSON
          : loadFileJSON;
      this.key++;
      this.loadInvoice();
    },
  },
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
      if (this.file === null && this.value !== null) {
        this.loadInvoice();
      }
    },
    loadInvoice() {
      http.fetch("/invoices/" + this.value).then((data) => {
        this.file = data.download_url;
      });
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
